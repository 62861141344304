import React from 'react';
import PropTypes from 'prop-types';

import i18n from '../../../i18n';

import User from '../../../models/User';
import Transfert from '../../../models/Transfert';
import Notification from '../../../models/Notification';

export default class MenuProfileUser extends React.PureComponent {
    static propTypes = {
        user: PropTypes.instanceOf(User).isRequired,
        notifications: PropTypes.instanceOf(Notification)
    };

    getUserFillingWriteMessage() {
        const profileNotif = this.props.notifications.getProfileNotification();
        if (profileNotif) {
            if (profileNotif.reason == Notification.PROFILE_REASON_COMPLETE_YOUR_PROFILE) {
                return i18n.t('fillingpanel:complete_profile');
            }
            return i18n.t('fillingpanel:complete_profile_to_receive_gp2', {
                gp: Transfert.MOTIVES_VALUE[Transfert.MOTIVE_FILLING_WRITE_PROFIL]
            });
        }
    }

    render() {
        const { user, notifications } = this.props;
        if (!user || _.isEmpty(notifications)) {
            return false;
        }
        return (
            <li>
                <a className="link-top-profile" href={i18n.t('user:user.edit.url', { id: user.id })}>
                    <i className="icon-header-profile-profile"></i>
                    <span className="link-title">{i18n.t('fillingpanel:my_profile')}</span>
                    {(() => {
                        const userFillingWriteMessage = this.getUserFillingWriteMessage();
                        if (user.get('profil').filling_write == 100) {
                            return (
                                <div className="top-menu-home-100">
                                    <p className="green-color">100%</p>
                                </div>
                            );
                        }
                        return (
                            <div>
                                <div className="top-menu-home-status">
                                    <span className={`number-progress view-all filling-user-${user.id}`}>
                                        {`${user.get('profil').filling_write}%`}
                                    </span>
                                    <div className="progress profil-bar">
                                        <div
                                            className={`progress-bar progress-bar-success progress-bar-user-${user.id}`}
                                            role="progressbar"
                                            aria-valuenow={user.get('profil').filling_write}
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                            style={{
                                                width: `${user.get('profil').filling_write}%`
                                            }}
                                        />
                                    </div>
                                </div>
                                {userFillingWriteMessage && (
                                    <div className="top-menu-messages">
                                        <p className="primary-strong">{userFillingWriteMessage}</p>
                                    </div>
                                )}
                            </div>
                        );
                    })()}
                </a>
            </li>
        );
    }
}
