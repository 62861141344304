import React from 'react';
import PropTypes from 'prop-types';

import i18n from '../../../i18n';

import Home from '../../../models/Home';

export default class HomeStatus extends React.PureComponent {
    static propTypes = {
        home: PropTypes.instanceOf(Home).isRequired
    };

    render() {
        const { home } = this.props;
        if (home.get('status') < Home.STATUS_ONLINE) {
            return <span className="pull-right view-all">{i18n.t('home:unlisted')}</span>;
        }
        if (home.get('completion_rate') == 100) {
            return (
                <div className="top-menu-home-100">
                    <p className="green-color">100%</p>
                </div>
            );
        }
        return (
            <div className="top-menu-home-status">
                <span className={`number-progress view-all filling-home-${home.id}`}>
                    {home.get('completion_rate')}%
                </span>
                <div className="progress profil-bar">
                    <div
                        className={`progress-bar progress-bar-success progress-bar-home-${home.id}`}
                        role="progressbar"
                        aria-valuenow={home.get('completion_rate')}
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ width: `${home.get('completion_rate')}%` }}
                    />
                </div>
            </div>
        );
    }
}
