import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Collapse } from 'react-bootstrap';

import { setTourAsViewed as setTourAsViewedAction } from '../../../actions/user';

import i18n from '../../../i18n';
import User from '../../../models/User';
import Transfert from '../../../models/Transfert';

import Loading from '../../common/Loading';
import Utils from '../../../utils/utils';
import {
    VERIFICATION,
    FILL_PROFILE,
    FILL_HOME,
    FIRST_SUBSCRIPTION,
    CONVERSATION_CREATED,
    SPONSORSHIP
} from '../../guestpoints/Guestpoints';

const gpTourName = 'header-gp-tour';

export class MenuGuestpoints extends React.PureComponent {
    static propTypes = {
        user: PropTypes.instanceOf(User),
        rewards: PropTypes.array,
        setTourAsViewed: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            rewards: [],
            menuGPExplanationOpened: this.shouldOpenGpItemByDefault(props.user)
        };

        this.menuGPExplanationClicked = this.menuGPExplanationClicked.bind(this);
        this.viewGPExplanationPage = this.viewGPExplanationPage.bind(this);
        this.displayReward = this.displayReward.bind(this);
        this.displayRewards = this.displayRewards.bind(this);

        if (this.shouldOpenGpItemByDefault(props.user)) {
            window.updateGPMenuBadge(1);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.filterUncompletedRewards(nextProps.rewards);
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.user && this.shouldOpenGpItemByDefault(this.props.user)) {
            // If user has never seen GP Tour, open the menu
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                menuGPExplanationOpened: true
            });
            window.updateGPMenuBadge(1);
        }
    }

    UNSAFE_componentWillMount() {
        this.filterUncompletedRewards(this.props.rewards);
    }

    filterUncompletedRewards(rewards) {
        if (!rewards) {
            return;
        }
        const completedGoals = rewards.filter((reward) => !reward.completed);
        this.setState({ rewards: completedGoals });
    }

    menuGPExplanationClicked() {
        this.setState((state) => ({
            menuGPExplanationOpened: !state.menuGPExplanationOpened
        }));
    }

    viewGPExplanationPage(e) {
        const { user } = this.props;
        e.preventDefault();
        if (!this.hasViewedGPTour()) {
            this.props.setTourAsViewed(gpTourName);
            setTimeout(() => {
                // setTimeout to leave time for the request to be fired
                window.location.href =
                    user && user.get('is_he_collection') ? i18n.t('url:collection-gp') : i18n.t('url:gp-cms');
            }, 500);
        } else {
            window.location.href =
                user && user.get('is_he_collection') ? i18n.t('url:collection-gp') : i18n.t('url:gp-cms');
        }
    }

    hasViewedGPTour() {
        const { user } = this.props;

        if (user && user.has('tours')) {
            return user.hasViewedTour(gpTourName);
        }

        return true;
    }

    shouldOpenGpItemByDefault(currentUser) {
        return Boolean(currentUser && !this.hasViewedGPTour());
    }

    displayReward(reward, information) {
        const { user } = this.props;

        if (reward) {
            return (
                <li key={reward.id}>
                    <a
                        className={`link-top-gp reward-${reward.type}`}
                        href={i18n.t(information.link, {
                            s: user.id
                        })}
                    >
                        {i18n.t(information.text, {
                            s: Transfert.CONVERSATION_NUMBER_OBJECTIVE
                        })}{' '}
                        <span className="gp-number pull-right view-all">
                            {' '}
                            + {reward.value} <span className="grey-color">GP</span>
                        </span>
                    </a>
                </li>
            );
        }
    }

    displayRewards() {
        const { rewards } = this.state;
        const fetch = (type) => rewards.find((reward) => reward.type === type);

        return (
            <>
                {this.displayReward(fetch(FILL_HOME.type), FILL_HOME)}
                {this.displayReward(fetch(FILL_PROFILE.type), FILL_PROFILE)}
                {this.displayReward(fetch(VERIFICATION.type), VERIFICATION)}
                {this.displayReward(fetch(FIRST_SUBSCRIPTION.type), FIRST_SUBSCRIPTION)}
                {this.displayReward(fetch(CONVERSATION_CREATED.type), CONVERSATION_CREATED)}
                {this.displayReward(fetch(SPONSORSHIP.type), SPONSORSHIP)}
            </>
        );
    }

    render() {
        const { user } = this.props;
        const { rewards, menuGPExplanationOpened } = this.state;
        if (!user || rewards.length == 0) {
            return (
                <ul className="dropdown-menu scrollable-menu moving-hover" role="menu" id="guestpoint_elt">
                    <li>
                        <Loading />
                    </li>
                </ul>
            );
        }
        const hasViewedGPTour = this.hasViewedGPTour();

        return (
            <ul className="dropdown-menu scrollable-menu moving-hover" role="menu" id="guestpoint_elt">
                <li className="navbar-collapsible-item">
                    <div className="navbar-collapsible-item-heading">
                        <a
                            className="link-top-help"
                            onClick={this.menuGPExplanationClicked}
                            aria-controls="menu-gp-explanations"
                            aria-expanded={menuGPExplanationOpened}
                        >
                            {i18n.t('common:menu-gp.explanations.title')}
                            {!hasViewedGPTour ? <span className="badge badge-menu">1</span> : null}
                            <span className="plus-menu">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="8"
                                    height="8"
                                    viewBox="0 0 8 8"
                                >
                                    <path
                                        className="horizontal-line"
                                        fill={`#${
                                            window.gtg.heCollection
                                                ? Utils.PRIMARY_COLOR_HE_COLLECTION
                                                : Utils.PRIMARY_COLOR
                                        }`}
                                        d="M0 3.4h8v1.2H0z"
                                    />
                                    <path
                                        className="vertical-line"
                                        fill={`#${
                                            window.gtg.heCollection
                                                ? Utils.PRIMARY_COLOR_HE_COLLECTION
                                                : Utils.PRIMARY_COLOR
                                        }`}
                                        d="M3.4 0h1.2v8H3.4z"
                                    />
                                </svg>
                            </span>
                        </a>
                    </div>
                    <Collapse in={menuGPExplanationOpened}>
                        <div id="menu-gp-explanations">
                            <div className="navbar-collapsible-item-body">
                                {i18n.t('common:menu-gp.explanations.content')}
                                <a
                                    className="plus-help-menu"
                                    href={
                                        user && user.get('is_he_collection')
                                            ? i18n.t('url:collection-gp')
                                            : i18n.t('url:gp-cms')
                                    }
                                    onClick={this.viewGPExplanationPage}
                                >
                                    {i18n.t('common:read-more')}
                                </a>
                            </div>
                        </div>
                    </Collapse>
                </li>

                {this.displayRewards()}

                <li>
                    <a className="link-top-gp" href={i18n.t(`url:url.gp.details`)}>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: i18n.t('user:you.have.guestpoint.header2', {
                                    count: user.get('number_guestpoint')
                                })
                            }}
                        ></span>
                        <span className="primary-color view-all pull-right">{i18n.t('common:view.all')}</span>
                    </a>
                </li>
            </ul>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    rewards: state.rewards
});

const mapDispatchToProps = (dispatch) => ({
    setTourAsViewed: bindActionCreators(setTourAsViewedAction, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuGuestpoints);
