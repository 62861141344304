import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FilePicker from '../widget/FilePicker';
import FilePlaceholder from '../user/verification/FilePlaceholder';
import FileToolbar from '../user/verification/FileToolbar';
import i18n from '../../i18n';
import FileTarget from '../user/verification/FileTarget';
import withDragDropContext from './withDragDropContext';
import Utils from '../../utils/utils';

class FileUpload extends PureComponent {
    static propTypes = {
        accepts: PropTypes.arrayOf(PropTypes.string),
        maxSize: PropTypes.number,
        labelIcon: PropTypes.string,
        labelText: PropTypes.string,
        onFileChange: PropTypes.func,
        errors: PropTypes.array,
        disabled: PropTypes.bool
    };

    static defaultProps = {
        accepts: ['application/pdf', 'image/png', 'image/jpeg'],
        maxSize: 5e6, // 5 Mega octets
        labelIcon: 'icon-id',
        labelText: i18n.t('user:verification.upload.identity'),
        errors: [],
        disabled: false
    };

    constructor(props) {
        super(props);

        this.state = {
            file: null,
            errors: props.errors
        };

        this.handleFile = this.handleFile.bind(this);
        this.handleClear = this.handleClear.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.errors !== prevProps.errors) {
            this.setState({
                errors: this.props.errors
            });
        }
    }

    handleFile(file) {
        const errors = [];

        if (!this.isSizeValid(file)) {
            errors.push('invalidSize');
        }

        if (!this.isTypeValid(file)) {
            errors.push('invalidType');
        }

        if (errors.length === 0) {
            this.setState({ file: file });
            this.props.onFileChange(file);
        }

        this.setState({ errors: errors });
    }

    handleClear(e) {
        e.stopPropagation();

        this.setState({ file: null });
        this.props.onFileChange(null);
    }

    isSizeValid(file) {
        const { maxSize } = this.props;

        return file !== null ? file.size <= maxSize : true;
    }

    isTypeValid(file) {
        const { accepts } = this.props;

        return file !== null ? accepts.includes(file.type) : true;
    }

    render() {
        const { accepts, maxSize, labelIcon, labelText, disabled } = this.props;
        const { file, errors } = this.state;

        const handleFile = (file) => this.handleFile(file);
        const handleClear = (e) => this.handleClear(e);

        return (
            <div className={`file-uploader ${disabled && 'file-uploader-disabled'}`}>
                <FilePicker accepts={accepts} file={file} onFile={handleFile}>
                    <FileTarget accepts={accepts} maxSize={maxSize} onDrop={handleFile}>
                        <FilePlaceholder file={file}>
                            <i className={classNames('icon', labelIcon)}></i>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: labelText
                                }}
                            />
                        </FilePlaceholder>
                        <FileToolbar file={file} onRemove={handleClear} />
                    </FileTarget>
                </FilePicker>
                {errors.length > 0 && (
                    <div className="has-error margin-top-xs text-center">
                        {errors.includes('noFile') && (
                            <span
                                className="help-block"
                                dangerouslySetInnerHTML={{
                                    __html: i18n.t('common:field_required')
                                }}
                            />
                        )}
                        {errors.includes('invalidSize') && (
                            <span
                                className="help-block"
                                dangerouslySetInnerHTML={{
                                    __html: i18n.t('user:verification.invalid.size', {
                                        maxSize: Utils.humanFileSize(maxSize)
                                    })
                                }}
                            />
                        )}
                        {errors.includes('invalidType') && (
                            <span
                                className="help-block"
                                dangerouslySetInnerHTML={{
                                    __html: i18n.t('user:verification.invalid.type')
                                }}
                            />
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export default withDragDropContext(FileUpload);
