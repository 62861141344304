import parser from 'query-string-parser';

import Model from './Model';
import Home from './Home';
import i18n from '../i18n';
import Api from '../api/Api';
import Utils from '../utils/utils';
import { isCollectionPage } from '../utils/url';
import Subscription from './Subscription';

export default class User extends Model {
    static STATUS_ON_VERIFICATION = -1;
    static STATUS_NOT_VERIFIED = 0;
    static STATUS_VERIFIED = 1;
    static STATUS_ONLY_CONTACT_BY_VERIFIED = 2;

    static STATUS_SPAM = -3;
    static STATUS_ABUSE = -2;
    static STATUS_INVALID = -1;
    static STATUS_CLOSED = 0;
    static STATUS_PENDING = 1;
    static STATUS_CONFIRMED = 2;

    static PHONE_TYPE_UNDEFINED = 0;
    static PHONE_TYPE_MOBILE = 1;
    static PHONE_TYPE_FIXED_LINE = 2;

    static TIDINESS_MANIAC = 1;
    static TIDINESS_TIDY = 2;
    static TIDINESS_RELAX = 3;
    static TIDINESS_CHAOTIC = 4;

    static PLANNING_VISIONARY = 1;
    static PLANNING_ORGANIZED = 2;
    static PLANNING_RELAX = 3;
    static PLANNING_DISORGANIZED = 4;

    static METRIC_M2 = 1;
    static METRIC_SQFT = 2;
    static CONVERT_SQFT_M2 = 0.09290304;

    static SOURCE_HE = 'HE'; // Was on HE before the merge
    static SOURCE_GTG = 'GTG'; // Was on GTG before the merge
    static SOURCE_HE_AND_GTG = 'HE_GTG'; // Was on both before the merge
    static SOURCE_NEW_HE = 'NEW_HE'; // After the merge
    static SOURCE_LHS = 'LHS';

    static REGULAR = 'REGULAR';
    static COLLECTION = 'COLLECTION';

    static REGULAR_STATUS = 'member_regular';
    static COLLECTION_STATUS = 'member_collection';
    static LEAD_STATUS = 'lead';
    static EXPIRED_STATUS = 'expired';

    static LOCALES = {
        1: 'fr',
        2: 'en',
        4: 'es',
        8: 'it',
        16: 'pt',
        32: 'nl',
        64: 'ar',
        128: 'ch',
        256: 'jp',
        512: 'co',
        1024: 'tu',
        2048: 'gr',
        4096: 'ru',
        8192: 'de',
        16384: 'da',
        32768: 'hr',
        65536: 'nb',
        131072: 'sv',
        262144: 'ca'
    };

    static LANGUAGES = {
        fr: 'Français',
        en: 'English',
        es: 'Español',
        it: 'Italiano',
        pt: 'Português',
        nl: 'Nederlands',
        ar: 'عربي',
        ch: '中文 ',
        jp: '日本語',
        co: '한국어',
        tu: 'Türkçe',
        gr: 'Eλληνικά',
        ru: 'Русский',
        de: 'Deutsch',
        da: 'Dansk',
        hr: 'hrvatski',
        nb: 'Norsk',
        sv: 'Svenska',
        ca: 'Català'
    };

    // Number of godson above which we don't get any more GP when sponsoring a friend
    static MAX_GODSONS_FOR_GP = 10;

    static DESCRIPTION_MIN_LENGTH = 150;

    static USER_PWD_POLICY = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9])(?=.{8,32})/;
    static EARLY_BIRDS = 1;
    static APPLICATION_FLOW = 2;
    static USER_COLLECTION = true;
    static ENOUGH_COMPLETION_RATE = 80;

    completionEarnings = {
        first_name: 5,
        last_name: 5,
        phone: 5,
        birth_date: 5,
        picture: 30,
        description: 30,
        emailVerif: 5,
        phoneVerif: 5
    };

    get defaults() {
        return {
            description: [],
            profil: {},
            tours: {},
            user_information: {}
        };
    }

    constructor(attributes) {
        super(attributes, {
            mapping: {
                firstName: 'first_name',
                lastName: 'last_name',
                verifiedStatus: 'verified_status',
                is_verified: 'verified_status'
            }
        });
    }

    parse(attributes, options) {
        attributes = super.parse(attributes, options);

        if (attributes.hasOwnProperty('currency') && !attributes.currency) {
            attributes.currency = 'EUR';
        }

        if (attributes.hasOwnProperty('profil') && _.isObject(attributes.profil)) {
            if (attributes.profil.hasOwnProperty('last_connexion_at')) {
                attributes.last_login_date = attributes.profil.last_connexion_at;
            }
            if (attributes.profil.hasOwnProperty('filling_write')) {
                attributes.completion_rate = attributes.profil.filling_write;
            }
            if (attributes.profil.hasOwnProperty('response_time')) {
                attributes.response_delay = attributes.profil.response_time;
            }
            if (attributes.profil.hasOwnProperty('user')) {
                delete attributes.profil.user;
            }
        }

        if (attributes.hasOwnProperty('user_information') && _.isObject(attributes.user_information)) {
            if (attributes.user_information.hasOwnProperty('user')) {
                delete attributes.user_information.user;
            }
        }

        if (attributes.hasOwnProperty('contact_by_verified_member')) {
            attributes.verified_only = attributes.contact_by_verified_member;
        }

        if (attributes.hasOwnProperty('homes') && _.isObject(attributes.homes)) {
            attributes.homes = attributes.homes.map((home) => {
                if (home instanceof Home === false) {
                    return new Home(home);
                }
                return home;
            });
        }

        if (attributes.hasOwnProperty('subscription_end_date') && attributes.subscription_end_date) {
            attributes.subscription_end_date = moment(attributes.subscription_end_date);
        }

        return attributes;
    }

    description() {
        const description = this.get('description').find(
            (desc) => desc.locale == i18n.language && desc.description
        );
        return _.result(description, 'description');
    }

    picture(width, height, smartResize, borders, getDefault) {
        if (this.has('images') && !_.isEmpty(this.get('images'))) {
            const image = _.first(this.get('images'));
            const params = parser.toQuery(
                Api.Image.getImageParams(width, height, smartResize, borders, image.rotate)
            );
            if (image.cdn_link) {
                return `${image.cdn_link}?${params}`;
            } else {
                return `${image.link}?${params}`;
            }
        } else if (this.has('pictures') && !_.isEmpty(this.get('pictures'))) {
            const id = _.first(this.get('pictures'));
            return Api.Image.getImageUrl(id, width, height, smartResize, borders);
        } else if (this.has('image')) {
            const params = parser.toQuery(Api.Image.getImageParams(width, height, smartResize, borders));
            return `${this.get('image')}?${params}`;
        } else if (getDefault) {
            // Return a default picture
            return `${Utils.getCloudfrontDomain()}/images/website/default-user-26x26.png`;
        }
    }

    languages() {
        const { language } = this.get('profil');
        const languages = [];

        // to get the default language if user did not set spoken language
        if (!language) {
            return [this.get('locale')];
        }

        for (const bit in User.LOCALES) {
            if (language & bit) {
                languages.push(User.LOCALES[bit]);
            }
        }
        return languages;
    }

    languagesAsLabels() {
        const languages = this.languages();
        return languages.map((locale) => User.LANGUAGES[locale]);
    }

    isVerified() {
        return this.get('verified_status') > User.STATUS_NOT_VERIFIED;
    }

    isPending() {
        return this.get('verified_status') === User.STATUS_ON_VERIFICATION;
    }

    isOnVerification() {
        return this.get('verified_status') === User.STATUS_ON_VERIFICATION;
    }

    hasVerificationPending() {
        return this.get('verification_pending');
    }

    hasNotStartedVerification() {
        return !this.isVerified() && !this.isOnVerification();
    }

    isFromApplicationFlowCollection() {
        return this.get('he_collection_version') >= User.APPLICATION_FLOW;
    }

    /**
     * @deprecated This function is deprecated and will be removed in future releases.
     * Please use `isUserEligibleCollection` instead.
     */
    isEligibleCollection(homeId) {
        let homes = this.get('homes');
        if (Array.isArray(homes) && homes.length > 0) {
            const currentUserHome = homes.find((home) => home.get('id') === homeId);
            if (currentUserHome) {
                return false;
            } else {
                homes = homes.find(
                    (home) => (home.get('is_he_collection') || home.get('is_collection')) === true
                );
                return homes && (homes.get('is_he_collection') || homes.get('is_collection'));
            }
        }
        return false;
    }

    isUserEligibleCollection() {
        return this.get('is_user_eligible_collection');
    }

    alreadyContacted() {
        return _.contains(Api.User.getContactedMembers(), this.id);
    }

    isAutoRenew() {
        return this.has('subscription') && this.get('subscription').auto_renew;
    }

    hasAlreadySubscribed() {
        return this.has('subscription');
    }

    getPrimaryHome() {
        let homes = this.get('homes');
        if (Array.isArray(homes) && homes.length > 0) {
            homes = Api.Home.sortByStatusAndCompletionRate(homes);
            return _.first(homes);
        }
        return null;
    }

    isEmailVerified() {
        return this.has('verification') ? this.get('verification').emailIsVerified : false;
    }

    isPhoneVerified() {
        return this.has('verification') ? this.get('verification').phoneIsVerified : false;
    }

    isSubscriber() {
        return this.has('is_subscribed') ? this.get('is_subscribed') : false;
    }

    isCollection() {
        return this.get('is_he_collection') === User.USER_COLLECTION;
    }

    hasHomeOnCollectionPage() {
        return this.get('homes').length > 0 && isCollectionPage(window.location.href);
    }

    hasVerifiedHome() {
        return this.get('homes').some((home) => home.get('is_verified'));
    }

    isCompletionAchieved(fieldName) {
        switch (fieldName) {
            case 'first_name':
            case 'last_name':
            case 'phone':
                return Boolean(this.has(fieldName) && this.get(fieldName));
            case 'picture':
                return Boolean(
                    (this.has('images') && !_.isEmpty(this.get('images'))) ||
                        (this.has('pictures') && !_.isEmpty(this.get('pictures'))) ||
                        this.has('image')
                );
            case 'description':
                return Boolean(
                    this.get('description') &&
                        this.get('description').find((d) =>
                            Boolean(d.description && d.description.length >= 150)
                        )
                );
            case 'birth_date':
                return Boolean(this.get('profil') && this.get('profil').birth_date);
            case 'emailVerif':
                return this.isEmailVerified();
            case 'phoneVerif':
                return this.isPhoneVerified();
            default:
                return false;
        }
    }

    getResponseTimeLabel() {
        const delay = this.get('response_delay');
        if (delay < 1 * 60) {
            return i18n.t('common:duration.hour', { count: 1 });
        } else if (delay < 12 * 60) {
            return i18n.t('common:duration.few_hours');
        } else if (delay < 24 * 60) {
            return i18n.t('common:duration.day', { count: 1 });
        } else if (delay < 48 * 60) {
            return i18n.t('common:duration.day', { count: 2 });
        } else if (delay < 7 * 24 * 60) {
            return i18n.t('common:duration.week', { count: 1 });
        } else if (delay < 2 * 7 * 24 * 60) {
            return i18n.t('common:duration.week', { count: 2 });
        } else if (delay < 3 * 7 * 24 * 60) {
            return i18n.t('common:duration.week', { count: 3 });
        } else if (delay < 30 * 24 * 60) {
            return i18n.t('common:duration.less_than_x_month', { count: 1 });
        } else {
            return i18n.t('common:duration.more_than_x_month', { count: 1 });
        }
    }

    wasHEMember() {
        return this.has('source') && this.get('source') === User.SOURCE_HE;
    }

    getNonEmptyDescriptionIfAvailable() {
        const des = this.get('description').find(
            (d) => !_.isEmpty(d.description) && d.description.length >= User.DESCRIPTION_MIN_LENGTH
        );
        return des;
    }

    hasProfileRatings() {
        const hasHomeWithRatings =
            this.get('homes') && this.get('homes').some((home) => home.get('global_rating') > 0);

        return Boolean(this.get('global_rating') > 0 || hasHomeWithRatings);
    }

    hasViewedTour(tourName) {
        let hasViewedTour = false;
        if (this.has('tours') && this.get('tours') && this.get('tours').length > 0) {
            this.get('tours').forEach((tour) => {
                if (tour.tour === tourName) {
                    hasViewedTour = true;
                }
            });
        }
        return hasViewedTour;
    }
    countHomes() {
        const homes = this.get('homes');
        return homes ? homes.length : 0;
    }

    countExchanges() {
        return this.get('number_exchange');
    }

    getLoyaltyLevel() {
        return this.get('loyalty') ? this.get('loyalty').level : 0;
    }

    getBestHomeRate(type = User.REGULAR) {
        let homes = this.get('homes');

        if (type === User.COLLECTION) {
            homes = homes.filter((home) => home.isCollection());
        } else {
            homes = homes.filter((home) => !home.isCollection());
        }

        if (homes.length === 0) {
            return null;
        }

        return homes.reduce((previousRate, home) => {
            return Math.max(previousRate, home.get('completion_rate'));
        }, 0);
    }

    getStatus() {
        let status = null;
        const currentSubscription = this.get('subscription');

        if (!currentSubscription) {
            status = User.LEAD_STATUS;
        } else {
            status = User.REGULAR_STATUS;
            const subscription = new Subscription(currentSubscription);

            if (subscription.get('end_on').isBefore(moment(), 'day')) {
                status = User.EXPIRED_STATUS;
            } else if (this.isCollection()) {
                status = User.COLLECTION_STATUS;
            }
        }

        return status;
    }

    getGroups() {
        return this.has('groups') ? this.get('groups') : null;
    }

    hasGroup() {
        const groups = this.getGroups();
        return groups ? groups.length > 0 : false;
    }

    canCollectionMemberContact() {
        return this.get('can_collection_member_contact') ?? true;
    }

    /**
     * Find at least one flagged home among user's homes
     * @param {array} homesFlaggedStatus
     * @returns {boolean}
     */
    static userIsFlaggedEligible(homesFlaggedStatus) {
        if (!homesFlaggedStatus) {
            return false;
        }
        const home = homesFlaggedStatus.find((flag) => parseInt(flag.status, 10) === Home.STATUS_ELIGIBLE);
        return Boolean(home);
    }

    /**
     * User has no home flagged eligible and have at least one home unflagged. User can have a home flagged as not eligible
     * @param {array} homesFlaggedStatus
     * @returns {boolean}
     */
    static userIsUnflaggeg(homesFlaggedStatus) {
        if (!homesFlaggedStatus) {
            return false;
        }
        const unflagged = homesFlaggedStatus.find((flag) => flag.status === Home.STATUS_UNFLAGGED);
        return !User.userIsFlaggedEligible(homesFlaggedStatus) && Boolean(unflagged);
    }

    /**
     * all homes are flagged not eligible
     * @param {array} homesFlaggedStatus
     * @returns {boolean}
     */
    static userIsFlaggedNotEligible(homesFlaggedStatus) {
        return !User.userIsFlaggedEligible(homesFlaggedStatus) && !User.userIsUnflaggeg(homesFlaggedStatus);
    }
}
