import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import i18n from '../../../i18n';
import Home from '../../../models/Home';
import User from '../../../models/User';
import Transfert from '../../../models/Transfert';
import Notification from '../../../models/Notification';
import Icons from '../../../utils/Icons';

import HomeStatus from './HomeStatus';
import CollectionLabel from '../../home/CollectionLabel';

export default class MenuProfileHome extends React.PureComponent {
    static propTypes = {
        home: PropTypes.instanceOf(Home),
        single: PropTypes.bool,
        notifications: PropTypes.instanceOf(Notification),
        shouldDisplayListingWithLhsLogo: PropTypes.bool,
        user: PropTypes.instanceOf(User)
    };

    static defaultProps = {
        single: false
    };

    getTitle() {
        if (this.props.single) {
            return i18n.t('fillingpanel:my_home');
        }
        if (this.props.home.get('address')) {
            return this.props.home.get('address');
        }
        return this.props.home.title();
    }

    getHomeCompletionRateMessage() {
        if (!this.props.notifications) {
            return;
        }
        const homeNotif = this.props.notifications.getHomeNotification(this.props.home.id);
        if (!homeNotif) {
            return;
        }
        switch (homeNotif.reason) {
            case Notification.HOME_REASON_OFFLINE:
                return i18n.t('fillingpanel:publish_home');
            case Notification.HOME_REASON_COMPLETE_YOUR_HOME:
                return i18n.t('fillingpanel:complete_home');
            case Notification.HOME_REASON_COMPLETE_YOUR_HOME_FIRST_TIME:
                return i18n.t('fillingpanel:complete_home_to_receive_gp2', {
                    gp: Transfert.MOTIVES_VALUE[Transfert.MOTIVE_FILLING_WRITE_HOME]
                });
            case Notification.HOME_REASON_COMPLETE_YOUR_HOME_EMPTY_CALENDAR:
                return i18n.t('home:add_availabilities');
            case Notification.HOME_REASON_NEW_CALENDAR_TYPES:
                return i18n.t('home:header-new-calendar-types');
            default:
        }
    }

    renderLHSListing() {
        return (
            <span className="lhs-profile-menu-listing">
                <i className="icon-header-profile-home" />
                <span className="icon-lhs">{Icons.transform(4)}</span>
            </span>
        );
    }

    render() {
        const { home, user, shouldDisplayListingWithLhsLogo } = this.props;
        if (!home) {
            return false;
        }
        const homeCompletionRateMessage = this.getHomeCompletionRateMessage();
        return (
            <li id={`top-menu-home-${home.id}`} className="home-link">
                <a className="link-top-profile" href={i18n.t('home:home.edit.url', { id: home.id })}>
                    {shouldDisplayListingWithLhsLogo && home.get('is_from_lhs') ? (
                        this.renderLHSListing()
                    ) : (
                        <i className="icon-header-profile-home"></i>
                    )}
                    <span
                        className={classNames('link-title', {
                            'top-name-adress-100': home.get('completion_rate') >= 100,
                            'top-name-adress-bar': home.get('completion_rate') < 100
                        })}
                    >
                        {this.getTitle()}
                    </span>
                    <HomeStatus home={home} />
                    {homeCompletionRateMessage && (
                        <div className="top-menu-messages">
                            <p className="primary-strong">{homeCompletionRateMessage}</p>
                        </div>
                    )}
                    <CollectionLabel home={home} user={user} />
                </a>
            </li>
        );
    }
}
